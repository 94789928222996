import SidebarNavigation from '../SidebarNavigation'
import TemplateFullWidth from '../TemplateFullWidth'
import * as style from './style.module.scss'
import Link from 'gatsby-link'
import React from 'react'

const PageMyPets = () => (
  <TemplateFullWidth>
    <div className={style.pageMyPets}>
      <SidebarNavigation />
      <div className={style.content}>
        <h1>My pets</h1>
        <p className={style.subline}>
          A complete profile helps your pet match with the best sitter.
        </p>
        <div className={style.container}>
          <div className={style.section}>
            <h2>Pet details</h2>
            <div className={style.formRow}>
              <div className={style.formGroup}>
                <label htmlFor='name'>
                  Pet name <span>*</span>
                </label>
                <input type='text' name='name'></input>
              </div>
              <div className={style.formGroup}>
                <label htmlFor='breed'>
                  Breed or breeds <span>*</span>
                </label>
                <input type='text' name='breed'></input>
              </div>
            </div>
            <div className={style.formRow}>
              <div className={style.formGroup}>
                <label htmlFor='age'>
                  Age <span>*</span>
                </label>
                <input type='text' name='age'></input>
              </div>
              <div className={style.formGroup}>
                <label htmlFor='color'>Coat color</label>
                <input type='text' name='color'></input>
              </div>
            </div>
            <div className={style.formRow}>
              <div className={style.formGroup}>
                <label htmlFor='routine'>
                  Describe your pets' daily routine, personality, or anything
                  else you think the sitter should know
                </label>
                <textarea rows='4' name='routine'></textarea>
              </div>
            </div>
          </div>
          <div className={style.section}>
            <h2>Photos</h2>
            <div className={style.formRow}>
              <div className={style.formGroup}>
                <label htmlFor='dog_face_photo'>
                  Upload a photo of your dogs face <span>*</span>
                </label>
                <input type='file' name='dog_face_photo'></input>
              </div>
              <div className={style.formGroup}>
                <label htmlFor='dog_face_photo'>
                  Upload a photo of your dog from the front <span>*</span>
                </label>
                <input type='file' name='dog_face_photo'></input>
              </div>
            </div>
            <div className={style.formRow}>
              <div className={style.formGroup}>
                <label htmlFor='dog_face_photo'>
                  Upload a photo of your dog standing <span>*</span>
                </label>
                <input type='file' name='dog_face_photo'></input>
              </div>
            </div>
          </div>
          <div className={style.section}>
            <h2>Food instructions</h2>
            <div className={style.formRow}>
              <div className={style.formGroup}>
                <label htmlFor='food_type'>
                  What kind of food does your dog eat?
                </label>
                <input type='text' name='food_type'></input>
              </div>
              <div className={style.formGroup}>
                <label htmlFor='meals_per_day'>Meals per day</label>
                <input type='number' name='meals_per_day'></input>
              </div>
              <div className={style.formGroup}>
                <label htmlFor='treats_allowed'>Treats allowed?</label>
                <input type='text' name='treats_allowed'></input>
              </div>
            </div>
            <div className={style.formGroup}>
              <label htmlFor='routine'>
                Describe your dogs food instructions (portion sizes, defrosting
                instructions, heating instructions, add water or broth, etc)
              </label>
              <textarea rows='4' name='routine'></textarea>
            </div>
          </div>
          <div className={style.section}>
            <h2>Additional details</h2>
          </div>
        </div>
      </div>
    </div>
  </TemplateFullWidth>
)

export default PageMyPets
